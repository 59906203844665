
import { TableQuery } from "@/api/requests/TableQuery";
import { GetReportsResponse } from "@/api/responses/reports/GetReportsResponse";
import { TableData } from "@/types/Table/TableData";
import { Component, Vue } from "vue-property-decorator";
import ReportPreview from "@/pages/reports/content/ReportPreview.vue";
import ACTIONS from "@/store/action-definitions";
import { Action } from "vuex-class";
import AddToCustomReports from "./AddToCustomReports.vue";

@Component({
	components: {
		ReportPreview,
		AddToCustomReports
	}
})
export default class ReportsTable extends Vue {
	private tableColumns = [
		{
			prop: "id",
			label: this.$t("reports.Id"),
			minWidth: 60
		},
		{
			prop: "name",
			label: this.$t("reports.Name"),
			minWidth: 200
		},
		{
			prop: "description",
			label: this.$t("reports.Description"),
			minWidth: 200
		},
		{
			prop: "noOfProducts",
			label: this.$t("reports.NoOfProducts"),
			minWidth: 100
		},
		{
			prop: "defaultLanguageName",
			label: this.$t("reports.DefaultLanguage"),
			minWidth: 150
		}
	];
	private tableData = {
		results: [],
		count: 0,
		currentPage: 1
	} as TableData<GetReportsResponse>;
	private perPageOptions = [5, 10, 15];
	private loading = true;
	private modalPreviewShow = false;
	private modalAddShow = false;

	$refs!: {
		reportPreviewRef: ReportPreview;
		addToCustomReportsRef: AddToCustomReports;
	};

	@Action(ACTIONS.SET_MESSAGE) setMessage: (message: {
		message: string;
		type: string;
	}) => void;

	hideAddModal(): void {
		this.modalAddShow = false;
	}

	async fetchData(pagination: TableQuery): Promise<void> {
		this.loading = true;
		const { data, success } = await this.$reports.getGlobalReports(
			pagination
		);
		if (success) {
			this.tableData = data;
		}
		this.loading = false;
	}

	async handleShow(id: number): Promise<void> {
		this.$refs.reportPreviewRef.fill(id);
		this.modalPreviewShow = true;
	}

	async handleAddVersion(id: number, name: string): Promise<void> {
		this.$refs.addToCustomReportsRef.fill(id, name);
		this.modalAddShow = true;
	}
}
