
import LoginForm from "./content/LoginForm.vue";
import { Component, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";

@Component({
	components: {
		LoginForm
	}
})
export default class Login extends Vue {
	@Getter("isLoggedIn") isLoggedIn!: boolean;
}
