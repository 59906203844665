import { apiConfig } from "@/api/axios-config";
import { apiPrinterConfig } from "@/api/axios-config";
import { Assessment } from "@/api/services/assessment";
import { AssessmentsTypes } from "@/api/services/assessmentsTypes";
import { Authentication } from "@/api/services/authentication";
import { Files } from "@/api/services/files";
import { Languages } from "@/api/services/languages";
import { Reports } from "@/api/services/reports";
import { SectionContents } from "@/api/services/sectionContents";
import { Sections } from "@/api/services/sections";
import { Settings } from "../api/services/settings";
import _Vue from "vue";

export default function AxiosPlugin(Vue: typeof _Vue): void {
	Vue.prototype.$reports = new Reports(apiConfig, apiPrinterConfig);
	Vue.prototype.$assessment = new Assessment(apiConfig);
	Vue.prototype.$sectionContents = new SectionContents(apiConfig);
	Vue.prototype.$sections = new Sections(apiConfig);
	Vue.prototype.$settings = new Settings(apiConfig);
	Vue.prototype.$assessmentsTypes = new AssessmentsTypes(apiConfig);
	Vue.prototype.$languages = new Languages(apiConfig);
	Vue.prototype.$authentication = new Authentication(apiConfig);
	Vue.prototype.$files = new Files(apiConfig);
}

declare module "vue/types/vue" {
	interface Vue {
		$reports: Reports;
		$assessment: Assessment;
		$sectionContents: SectionContents;
		$sections: Sections;
		$settings: Settings;
		$assessmentsTypes: AssessmentsTypes;
		$languages: Languages;
		$authentication: Authentication;
		$files: Files;
	}
}
