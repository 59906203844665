
import { ReportSectionDto } from "@/api/responses/reports/dtos/ReportSectionDto";
import { Component, Emit, Prop, Vue, Watch } from "vue-property-decorator";

@Component
export default class CloneSectionModal extends Vue {
	@Prop({ type: Boolean }) showModal!: boolean;
	@Prop({ type: Object }) section!: ReportSectionDto;

	private isVisible = false;
	private clonedSectionName = "";

	get isDataValid() {
		return this.clonedSectionName !== "";
	}

	@Emit("onClose")
	onModalClose() {
		return;
	}

	@Emit("onClone")
	onSectionClone() {
		return {
			sectionId: this.section.id,
			cloneSectionRequest: {
				reportId: +this.$route.query.reportId,
				sectionName: this.clonedSectionName
			}
		};
	}

	@Watch("showModal")
	onModalVisibilityChange(newVal: boolean) {
		this.isVisible = newVal;
		this.clonedSectionName = "";
	}
}
