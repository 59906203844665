
import ACTIONS from "@/store/action-definitions";
import { Component, Vue, Watch } from "vue-property-decorator";
import { Action, Getter, namespace } from "vuex-class";
import CreateSectionModal from "./content/sections/CreateSectionModal.vue";
import CloneSectionModal from "./content/sections/CloneSectionModal.vue";
import { EditableSection } from "@/types/Editor/EditableSection";
import { SaveSectionRequest } from "@/api/requests/sections/SaveSectionRequest";
import { ReportSectionOrderRequest } from "@/api/requests/reports/ReportSectionOrderRequest";
import { TableQuery } from "@/api/requests/TableQuery";
import { SaveSectionContentRequest } from "@/api/requests/sectionContents/SaveSectionContentRequest";
import { GetSectionResponse } from "@/api/responses/sections/GetSectionResponse";
import { ReportSectionDto } from "@/api/responses/reports/dtos/ReportSectionDto";
import { GetAssessmentsTypeResponse } from "@/api/responses/assessmentsTypes/GetAssessmentsTypeResponse";
import { Language } from "@/types/Language";
import { GetAssessmentContext } from "@/api/responses/assessments/GetAssessmentContext";
import { StoreSection } from "@/types/Editor/StoreSection";
import { CloneSectionRequest } from "@/api/requests/sections/CloneSectionRequest";
import { ModalSectionType } from "@/types/Enums/ModalSectionType";
import { buildHtml } from "@/helpers/htmlReportBuilderHelper";
import { ReportDataDto } from "@/api/responses/reports/dtos/ReportDataDto";
import { RenderReportRequest } from "@/api/requests/reports/RenderReportRequest";

const reportEditorModule = namespace("reportEditor");

@Component({
	components: {
		CreateSectionModal,
		CloneSectionModal
	}
})
export default class ReportsPage extends Vue {
	private section = {} as GetSectionResponse;
	private assessmentTypes: Array<GetAssessmentsTypeResponse> = [];
	private drag = false;
	private dialogData = {
		show: false,
		onConfirm: Function(),
		onCancel: Function(),
		message: ""
	};
	private createSectionModalShow = false;
	private sectionModalType = "";
	private cloneSectionModalShow = false;
	private selectedSectionIsBase: boolean;
	private orderChanged = false;
	private editableSection: EditableSection = {
		id: 0,
		name: "",
		assessmentType: this.assessmentTypes[0],
		reportId: +this.$route.query.reportId,
		isBaseSection: false,
		addTemplate: false
	};
	private selectedLanguages = [] as Language[];
	private languages = [] as Language[];
	private isLoadingLanguages = false;
	private showChoosenLanguages = false;
	private activeName = "1";
	private emptyReport = false;
	private isBaseReport = true;
	private previewBaseSection = false;
	private clonedSectionId = 0;
	private sectionToClone = {} as ReportSectionDto;

	@Action(ACTIONS.SET_EDITOR_SECTIONS) setEditorSections: (
		sections: ReportSectionDto[]
	) => void;

	@Action(ACTIONS.SET_EDITOR_DIRTY) setEditorDirty: (
		editorDirty: boolean
	) => void;

	@Action(ACTIONS.SET_CURRENT_SECTION) setCurrentSectionId: (
		id: number
	) => void;

	@Action(ACTIONS.SET_MESSAGE) setMessage: (message: {
		message: string;
		type: string;
	}) => void;

	@Action(ACTIONS.SET_SECTION_DETACHED) setSectionDetached: (
		isSectionClonned: boolean
	) => void;

	@reportEditorModule.Action(ACTIONS.SET_ASSESSMENT_CONTEXT)
	setAssessmentContext: (assessmentContext: GetAssessmentContext) => void;

	@reportEditorModule.Action(ACTIONS.SET_TABS_TOUCHED_TEXT)
	setTabsTouchedText: (tabsTouchedText: boolean) => void;

	@reportEditorModule.Action(ACTIONS.SET_TABS_TOUCHED_RULES)
	setTabsTouchedRules: (tabsTouchedRules: boolean) => void;

	@reportEditorModule.Action(ACTIONS.SET_ASSESSMENT_TYPES)
	setAssessmentTypes: (
		assessmentTypes: Array<GetAssessmentsTypeResponse>
	) => void;

	@reportEditorModule.Action(ACTIONS.SET_PREVIEW_HTML)
	setPreviewHtml: (previewHtml: string) => void;

	@reportEditorModule.Action(ACTIONS.SET_PREVIEW_ERRORS)
	setPreviewErrors: (previewErrors: string[]) => void;

	@reportEditorModule.Action(ACTIONS.SET_SECTION_ID)
	setSectionId: (sectionId: number) => void;

	@reportEditorModule.Action(ACTIONS.SET_SECTION_NAME)
	setSectionName: (sectionName: string) => void;

	@reportEditorModule.Action(ACTIONS.SET_SECTION)
	setSection: (section: StoreSection) => void;

	@Getter("getCurrentSectionId") currentSectionId!: number;
	@Getter("getEditorSections") sections!: ReportSectionDto[];

	@reportEditorModule.Getter("currentSection")
	currentSection: StoreSection;

	@reportEditorModule.Getter("currentSectionContent")
	currentSectionContent: any;

	@reportEditorModule.Getter("assessmentContext")
	assessmentContext: GetAssessmentContext;

	@reportEditorModule.Getter("reportClass")
	reportClass: string;

	@Watch("currentSectionId")
	async onSectionChange(newVal: number) {
		if (newVal > 0) {
			this.section = await this.fetchSection(newVal);
			this.setSection(this.section);
		}
	}

	@Watch("sections")
	onOrderChange(newVal: ReportSectionDto[], oldVal: ReportSectionDto[]) {
		if (oldVal.length > 0) this.orderChanged = true;
	}

	handlerError(message: string): void {
		this.setMessage({
			message: message,
			type: "danger"
		});
	}

	async fetchContext(): Promise<void> {
		const { data, success } = await this.$assessment.getAssessmentContext();
		if (success) this.setAssessmentContext(data);
	}

	async fetchSection(id: number): Promise<GetSectionResponse> {
		const { data, success } = await this.$sections.getSection(id);
		if (success) {
			return data;
		}
		this.handlerError(this.$t("errors.FetchingSections").toString());
		return {} as GetSectionResponse;
	}

	async fetchReport(): Promise<void> {
		const { reportId } = this.$route.query;
		if (!reportId) this.$router.push({ name: "home" });
		else {
			const result = await this.$reports.getReportsById(+reportId);
			if (result.success) {
				this.emptyReport = !result.data.sections.length;
				this.setEditorSections(result.data.sections);
				this.isBaseReport = result.data.isBaseReport;
				this.selectedLanguages = result.data.availableLanguages.map(
					(lang) => {
						if (lang.id === result.data.defaultLanguage.id) {
							lang.name = lang.name.concat(" ★");
							lang.isDefault = true;
						} else {
							lang.isDefault = false;
						}
						return lang;
					}
				);
			}
		}
	}

	async fetchAssessmentTypes(): Promise<void> {
		const {
			data,
			success
		} = await this.$assessmentsTypes.getAssessmentTypes();
		if (success) {
			data.unshift({
				id: null,
				name: "none",
				description: "none",
				assessmentStyles: []
			});
			this.setAssessmentTypes(data);
			this.assessmentTypes = data;
		} else {
			this.handlerError(
				this.$t("errors.FetchingAssessmentTypes").toString()
			);
		}
	}

	async detachSection(sectionId: number) {
		this.setSectionDetached(true);
		const { success } = await this.$reports.detachSection(
			+this.$route.query.reportId,
			sectionId
		);
		if (success) {
			this.setMessage({
				message: this.$t(
					"reportsEditor.DetachSectionSuccess"
				).toString(),
				type: "success"
			});
		}
		await this.fetchReport();
		this.switchSection(this.sections[this.sections.length - 1].id);
		this.activeName = this.sections.length.toString();
		this.closeSectionModal();
		this.setSectionDetached(false);
	}

	async sectionSave({
		updated,
		section
	}: {
		updated: boolean;
		section: EditableSection;
	}) {
		if (this.sections.some((item) => item.name === section.name)) {
			this.setMessage({
				message: this.$t(
					"reportsEditor.SectionNameMustBeUniqueInReport"
				).toString(),
				type: "warning"
			});
			return;
		}
		const sectionToSave: SaveSectionRequest = {
			...section,
			idAssessmentType: section.assessmentType
				? section.assessmentType.id
				: null
		};

		let saveMethod = null;
		if (updated) saveMethod = this.$sections.updateSection(sectionToSave);
		else saveMethod = this.$sections.addSection(sectionToSave);

		const { success } = await saveMethod;

		if (success) {
			this.setMessage({
				message: this.$t("reportsEditor.SaveSectionSuccess").toString(),
				type: "success"
			});
		}
		await this.fetchReport();
		this.switchSection(this.sections[this.sections.length - 1].id);
		this.activeName = this.sections.length.toString();
		this.closeSectionModal();
	}

	async sectionRun(
		contentlanguageId: number,
		textResourceslanguageId: number,
		reportStyle: string,
		sectionContent?: SaveSectionContentRequest,
		saveBeforeRender = true
	) {
		try {
			if (saveBeforeRender && sectionContent) {
				await this.sectionContentSave(sectionContent);
			}
			
			const reportId = parseInt(this.$route.query["reportId"] as string);
			const response = await this.$reports.renderHtmlReport(
			{
				languageId: contentlanguageId,
				assessmentId: 0,
				reportId: reportId,
				specificSectionIds: [this.currentSectionId],
				reportStyle: reportStyle
			} as RenderReportRequest);

			if (response.status === 200) {
				let html = "";
				const data = response.data as ReportDataDto;
				if (data.errors.length) {
					this.setPreviewErrors(data.errors);
					data.errors.forEach((error: string) => {
						html += `<div class='render-error-item'>Error: ${error}</div>`;
					});
				} else html = buildHtml(data);

				this.setPreviewHtml(html);
			}
		} catch (error) {
			this.handlerError(error);
		}
	}

	async sectionContentSave(
		sectionContent: SaveSectionContentRequest,
		callback?: Function,
		confirmationRequired?: boolean
	) {
		const saveSectionContent = async () => {
			const { success } = await this.$sectionContents.saveSectionContent(
				this.section.id,
				sectionContent
			);
			if (success) {
				this.setTabsTouchedText(false);
				this.setTabsTouchedRules(false);
				this.setMessage({
					message: this.$t(
						"reportsEditor.SectionContentSaveSuccess"
					).toString(),
					type: "success"
				});
				this.onSectionChange(this.section.id);
			}
		};
		if (confirmationRequired)
			this.openDialog(
				() => {
					saveSectionContent();
					callback ? callback() : Function();
					this.closeDialog();
				},
				() => {
					this.closeDialog();
					callback ? callback() : Function();
				},

				this.$t("modals.UnsavedChanges").toString()
			);
		else saveSectionContent();
	}

	public openDialog(
		onConfirm: Function,
		onCancel: Function,
		message: string
	) {
		this.dialogData = {
			show: true,
			onConfirm,
			onCancel,
			message
		};
	}

	public closeDialog() {
		this.dialogData.show = false;
	}

	public openCreateSectionModal(modalType: ModalSectionType) {
		this.createSectionModalShow = true;
		this.sectionModalType = ModalSectionType[modalType];
	}

	public openCloneSectionModal(section: ReportSectionDto) {
		this.sectionToClone = section;
		this.cloneSectionModalShow = true;
	}

	public closeSectionModal() {
		this.createSectionModalShow = false;
		this.cloneSectionModalShow = false;
	}

	async saveOrder() {
		const reportSectionOrder: ReportSectionOrderRequest = {
			reportId: +this.$route.query.reportId,
			reportSections: this.sections.map((section, index) => {
				return {
					sectionId: section.id,
					order: index
				};
			})
		};
		const { success } = await this.$reports.reorderReportSections(
			reportSectionOrder
		);
		if (success) {
			this.setMessage({
				message: this.$t(
					"reportsEditor.SectionOrderSaveSuccess"
				).toString(),
				type: "success"
			});
		}
	}

	async sectionClone({
		sectionId,
		cloneSectionRequest
	}: {
		sectionId: number;
		cloneSectionRequest: CloneSectionRequest;
		callback: Function;
	}) {
		const { success } = await this.$sections.cloneSection(
			sectionId,
			cloneSectionRequest
		);
		if (success) {
			this.detachSection(sectionId);
			this.setMessage({
				message: this.$t(
					"reportsEditor.CloneSectionSuccess"
				).toString(),
				type: "success"
			});
		} else this.fetchReport();
	}

	isBaseSection(section: ReportSectionDto) {
		return !section.owningAccountId;
	}

	addSection() {
		this.editableSection = {
			id: 0,
			name: "",
			assessmentType: this.assessmentTypes[0],
			reportId: +this.$route.query.reportId,
			isBaseSection: false,
			addTemplate: false
		};
		if (this.currentSectionContent?.isEditorDirty) {
			this.openDialog(
				() => {
					this.openCreateSectionModal(ModalSectionType.Create);
					this.closeDialog();
				},
				this.closeDialog,
				this.$t("table.AreYouSure").toString()
			);
			return;
		}
		this.openCreateSectionModal(ModalSectionType.Create);
	}

	editSection(section: ReportSectionDto) {
		this.editableSection = {
			id: section.id,
			name: section.name,
			assessmentType: this.assessmentTypes.filter(
				(type) => type.id === section.assessmentTypeId
			)[0],
			reportId: +this.$route.query.reportId,
			isBaseSection: this.isBaseSection(section),
			addTemplate: false
		};

		this.openCreateSectionModal(ModalSectionType.Edit);
	}

	public switchSection(id: number) {
		this.setCurrentSectionId(id);
		this.setPreviewErrors([]);
		this.setPreviewHtml("");
		this.closeDialog();
		if (this.selectedSectionIsBase) {
			this.sectionRun(
				this.currentSectionContent.language.id,
				this.currentSectionContent.language.id,
				this.reportClass
			);
		}
	}

	public confirmSwitch(id: number, isBaseSection: boolean) {
		if (id !== this.currentSectionId) {
			if (this.currentSectionContent?.isEditorDirty)
				this.openDialog(
					() => {
						this.selectedSectionIsBase = isBaseSection;
						this.switchSection(id);
					},
					this.closeDialog,
					this.$t("table.AreYouSure").toString()
				);
			else {
				this.selectedSectionIsBase = isBaseSection;
				this.switchSection(id);
			}
		}
	}

	async created() {
		await Promise.all([
			this.fetchReport(),
			this.fetchContext(),
			this.fetchAssessmentTypes()
		]);

		await this.getLanguages("");
		await this.sectionRun(
			this.languages.find((language) => language.isDefault)?.id ||
				this.languages[0].id,
			this.languages.find((language) => language.isDefault)?.id ||
				this.languages[0].id,
			"app2"
		);
	}

	destroyed() {
		this.setEditorSections([]);
	}

	get dragOptions() {
		return {
			animation: 200,
			group: "description",
			disabled: false,
			ghostClass: "ghost"
		};
	}

	async getLanguages(query: string): Promise<void> {
		this.isLoadingLanguages = true;
		const { data, success } = await this.$languages.getLanguages({
			page: 1,
			pageSize: 25,
			sortDirection: "ascending",
			sortColumn: "name",
			searchQuery: query,
			shouldSort: true
		} as TableQuery);
		if (success) {
			this.languages = data.results.map((lang) => {
				if (
					lang.id ===
					(this.selectedLanguages?.find(
						(language) => language.isDefault
					)?.id ?? 0)
				) {
					lang.name = lang.name.concat(" ★");
					lang.isDefault = true;
				} else {
					lang.isDefault = false;
				}
				return lang;
			});
		}
		this.isLoadingLanguages = false;
	}

	async deleteLanguage(language: Language): Promise<void> {
		if (language.isDefault === true) {
			this.selectedLanguages.push(language);
		} else {
			const { success } = await this.$reports.deactivateLanguage(
				+this.$route.query.reportId,
				language.id
			);
			if (success) {
				this.setMessage({
					message: this.$t(
						"reportsEditor.DeleteLanguageSuccess"
					).toString(),
					type: "success"
				});
			}
		}
	}

	async addLanguage(language: Language): Promise<void> {
		const { success } = await this.$reports.activateLanguage(
			+this.$route.query.reportId,
			language.id
		);
		if (success) {
			const { sectionContents } = await this.fetchSection(
				this.section.id
			);

			const currentSection = this.currentSection;
			currentSection.sectionContents.push(
				sectionContents[sectionContents.length - 1]
			);
			this.setSection(currentSection);
			this.setMessage({
				message: this.$t("reportsEditor.AddLanguageSuccess").toString(),
				type: "success"
			});
		}
	}

	truncateText(text: string): string {
		if (text.length > 30) {
			return text.substring(0, 30) + "...";
		}
		return text;
	}
}
