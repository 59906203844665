import cookieHelper from "@/helpers/cookieHelper";
import i18n from "@/locales/i18n";
import store from "@/store";
import ACTIONS from "@/store/action-definitions";
import axios, {
	AxiosError,
	AxiosInstance,
	AxiosRequestConfig,
	AxiosResponse
} from "axios";
import { ApiResponse } from "./responses/ApiResponse";

export class Api {
	api: AxiosInstance;
	apiPrinter: AxiosInstance;

	public constructor(
		config?: AxiosRequestConfig,
		configPrinter?: AxiosRequestConfig
	) {
		this.api = axios.create(config);
		this.api.interceptors.request.use(
			async (config: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
				const token = cookieHelper.getSessionCookie();

				const requestConfig = config;
				requestConfig.headers.Authorization = `Bearer ${token}`;
				requestConfig.headers["Accept-Language"] =
					store.getters.getLocale;
				return requestConfig;
			},
			(error) => Promise.reject(error)
		);

		this.apiPrinter = axios.create(configPrinter);
		this.apiPrinter.interceptors.request.use(
			async (config: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
				const token = cookieHelper.getSessionCookie();

				const requestConfig = config;
				requestConfig.headers.Authorization = `Bearer ${token}`;
				requestConfig.headers["Accept-Language"] =
					store.getters.getLocale;
				return requestConfig;
			},
			(error) => Promise.reject(error)
		);
	}

	public get<TResult, TResponse = AxiosResponse<TResult>>(
		url: string,
		config?: AxiosRequestConfig
	): Promise<TResponse> {
		return this.api.get(url, config);
	}

	public put<TResult, TPayload, TResponse = AxiosResponse<TResult>>(
		url: string,
		data: TPayload,
		config?: AxiosRequestConfig
	): Promise<TResponse> {
		return this.api.put(url, data, config);
	}

	public async delete<TResult, TResponse = AxiosResponse<TResult>>(
		url: string,
		config?: AxiosRequestConfig
	): Promise<TResponse> {
		return await this.api.delete(url, config);
	}

	public post<TResult, TPayload, TResponse = AxiosResponse<TResult>>(
		url: string,
		data: TPayload,
		config?: AxiosRequestConfig
	): Promise<TResponse> {
		return this.api.post(url, data, config);
	}

	public successResponse<TResult>(
		response: AxiosResponse<TResult>
	): AxiosResponse<TResult> {
		return response;
	}

	public success<TResult>(response: AxiosResponse<TResult>): TResult {
		return response.data;
	}

	public errorResponse<T>(error: AxiosError<T>): AxiosResponse<T> {
		if (error.response) {
			store.dispatch("setMessage", {
				message: `${
					error.response?.data
						? error.response?.data
						: error.response?.statusText
				}`,
				type: "danger"
			});
			if (error.response.status === 401) {
				store.dispatch(ACTIONS.DELETE_SESSION);
			}

			return error.response as AxiosResponse<T>;
		} else {
			store.dispatch("setMessage", {
				message: i18n.t("errors.ConnectionError"),
				type: "danger"
			});
		}
		return { status: 500 } as AxiosResponse<T>;
	}

	public error<T>(error: AxiosError<T>): ApiResponse<any> {
		if (error.response) {
			store.dispatch("setMessage", {
				message: `${
					error.response?.data
						? error.response?.data
						: error.response?.statusText
				}`,
				type: "danger"
			});
			if (error.response.status === 401) {
				store.dispatch(ACTIONS.DELETE_SESSION);
			}
		} else {
			store.dispatch("setMessage", {
				message: i18n.t("errors.ConnectionError"),
				type: "danger"
			});
		}
		return { success: false } as ApiResponse;
	}
}
